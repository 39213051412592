if (document.getElementById("map")) {
	var path = document.getElementById("map").getAttribute("data-csv-url");
	var zoom = document.getElementById("map").getAttribute("data-zoom");
	var center = document.getElementById("map").getAttribute("data-center");

	center = center.split(",").map(function (item) {
		return parseFloat(item, 10);
	});

	var map = L.map("map", {
		gestureHandling: true,
		fullscreenControl: true,
	}).setView(center, zoom);
	L.tileLayer(
		// mapbox://styles/mapbox/light-v10
		// mapbox://styles/adviceas/cl0jfd6oj001014lddt4cwi46
		"https://api.mapbox.com/styles/v1/adviceas/cl0jfd6oj001014lddt4cwi46/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWR2aWNlYXMiLCJhIjoiY2wwNnR6Zzc1MTR4bzNkcGRyOXBoNjZ4NyJ9.hlT4MwCwuVad9OjPw_bt5Q",
		{
			attribution:
				'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
			maxZoom: 18,
			minZoom: 2,
			id: "adviceas/cl0jfd6oj001014lddt4cwi46",
			tileSize: 512,
			zoomOffset: -1,
			accessToken:
				"pk.eyJ1IjoiYWR2aWNlYXMiLCJhIjoiY2wwNnR6Zzc1MTR4bzNkcGRyOXBoNjZ4NyJ9.hlT4MwCwuVad9OjPw_bt5Q",
		}
	).addTo(map);

	var markers = L.markerClusterGroup({
		showCoverageOnHover: false,
		animateAddingMarkers: true,
		maxClusterRadius: 50,
		spiderfyOnMaxZoom: true,
		animate: true,
		iconCreateFunction: function (cluster) {
			let size = 30;
			return L.divIcon({
				html: `<div class="custom-clustericon" style="line-height: ${size}px; width: ${size}px; height: ${size}px">
        <div class="custom-clustericon-label" aria-label="" tabindex="0" style="width: ${size}px;">
  <span aria-hidden="true">${cluster.getChildCount()}</span>
</div></div>`,
			});
		},
	});

	const icons = Array(
		L.icon({
			iconUrl:
				window.location.origin + "/wp-content/themes/ee/assets/img/pin-sun.svg",
			name: "Solar PV",
			label: "Solar",
			legendMarkup: "<span class='legend-icon legend-icon-sun'></span>Solar PV",
			markers: L.featureGroup.subGroup(markers),
		}),
		L.icon({
			iconUrl:
				window.location.origin +
				"/wp-content/themes/ee/assets/img/pin-wind.svg",
			name: "Onshore wind",
			label: "Wind",
			legendMarkup:
				"<span class='legend-icon legend-icon-wind'></span>Onshore wind",
			markers: L.featureGroup.subGroup(markers),
		}),
		L.icon({
			iconUrl:
				window.location.origin +
				"/wp-content/themes/ee/assets/img/pin-offshore.svg",
			name: "Offshore wind",
			label: "Wind",
			legendMarkup:
				"<span class='legend-icon legend-icon-offshorewind'></span>Offshore wind",
			markers: L.featureGroup.subGroup(markers),
		}),
		L.icon({
			iconUrl:
				window.location.origin +
				"/wp-content/themes/ee/assets/img/pin-floatingsolar.svg",
			name: "Floating solar",
			label: "Floating solar",
			legendMarkup:
				"<span class='legend-icon legend-icon-floatingsolar'></span>Floating solar",
			markers: L.featureGroup.subGroup(markers),
		}),
		L.icon({
			iconUrl:
				window.location.origin + "/wp-content/themes/ee/assets/img/pin-ptx.svg",
			name: "PtX",
			label: "PtX",
			legendMarkup: "<span class='legend-icon legend-icon-ptx'></span>PtX",
			markers: L.featureGroup.subGroup(markers),
		}),
		L.icon({
			iconUrl:
				window.location.origin +
				"/wp-content/themes/ee/assets/img/pin-heatpump.svg",
			name: "Heat pump",
			label: "Heat pump",
			legendMarkup:
				"<span class='legend-icon legend-icon-heatpump'></span>Heat pump",
			markers: L.featureGroup.subGroup(markers),
		}),
		L.icon({
			iconUrl:
				window.location.origin +
				"/wp-content/themes/ee/assets/img/pin-storage.svg",
			name: "Storage",
			label: "Storage",
			legendMarkup:
				"<span class='legend-icon legend-icon-storage'></span>Storage",
			markers: L.featureGroup.subGroup(markers),
		})
	);

	let overlayMaps = {};

	Papa.parse(path, {
		download: true,
		encoding: "UTF8",
		header: true,
		transformHeader: function (e) {
			return e.toLowerCase().replace(/ /g, "_");
		},
		complete: (results) => {
			// console.log(results);
			results.data.forEach((project) => {
				if (project.latitudes && project.longitude) {
					const icon = icons.find(
						(icon) => project.technology == icon.options.name
					);
					if (!icon?.options) {
						return null;
					}
					const marker =
						// markers.addLayer(
						L.marker([project.latitudes, project.longitude], {
							title: project.name,
							icon: L.icon({
								className: "ee-custom-pin",
								popupAnchor: [0, -15],
								iconUrl: icon.options.iconUrl,
								name: project.name,
								iconSize: [30, 30],
							}),
						}).bindPopup(
							`<b>${project.name}, ${project.country}</b><br>MW: ${
								project.mw
							}<br>Phase: ${project.phase
								.replace("4. ", "")
								.replace("5. ", "")}<br>Technology: ${
								project.technology
							}<br/>COD Year: ${project.cod}<br/>
							${
								project.link
									? `<br/>
							<div>
                <a class="c-button" href="${
									window.location.href + "/" + project.link
								}">Read more</a>
            </div>`
									: ""
							}`,
							{
								minWidth: 200,
								minHeight: 200,
							}
							// )
						);

					const currentIconGroup = icons.find(
						(icon) => icon.options.name == project.technology
					);
					if (currentIconGroup) {
						marker.addTo(currentIconGroup.options.markers);
					} else {
						console.log(project.technology);
					}
				}
			});
			if (!zoom || !center) {
				map.flyToBounds(markers.getBounds());
			} else {
				map.flyTo(center.split(", "), zoom);
			}

			icons.map((icon) => {
				// console.log(icon.options.markers.getLayers());
				// console.log(solarMarkers.getLayers());
				if (icon.options.markers.getLayers().length) {
					overlayMaps[icon.options.legendMarkup] = icon.options.markers;
				}
			});

			L.control
				.layers(null, overlayMaps, {
					position: "bottomright",
					collapsed: false,
				})
				.addTo(map);
		},
	});

	icons.map((icon) => {
		icon.options.markers.addTo(map);
	});
	markers.addTo(map);
}
