jQuery(document).ready(function ($) {
	if ($(".c-accordion").length) {
		var accordionHeadline = $(".c-accordion__headline"),
			accordionContent = $(".c-accordion__content");

		accordionHeadline.keypress(function (e) {
			var key = e.which;
			if (key == 13) {
				$(this).click();
				return false;
			}
		});

		function toggleAccordion(accordionHeadline) {
			if ($(accordionHeadline).hasClass("is-open")) {
				$(accordionHeadline).removeClass("is-open");
				$(accordionHeadline)
					.closest(".c-accordion__item")
					.removeClass("is-open");
				$(accordionHeadline).next(accordionContent).removeClass("is-open");
				$(accordionHeadline).attr("aria-expanded", "false");
			} else {
				$(accordionHeadline).addClass("is-open");
				$(accordionHeadline).closest(".c-accordion__item").addClass("is-open");
				$(accordionHeadline).next(accordionContent).addClass("is-open");
				$(accordionHeadline).attr("aria-expanded", "true");
			}
		}

		accordionHeadline.on("click", function (e) {
			toggleAccordion($(this));
		});

		var hash = window.location.hash;
		if (hash !== "") {
			if (hash.includes("?")) {
				hash = hash.split("?")[0];
			}
			$(accordionHeadline).each(function (i) {
				if (hash === "#" + $(this).attr("id")) {
					$(this).addClass("is-open");
					$(this).closest(".c-accordion__item").addClass("is-open");
					$(this).next(accordionContent).addClass("is-open");
					$(this).attr("aria-expanded", "true");
				}
			});
		}
	}
});
