var secondaryMenuToggle = document.querySelector(".c-menu-secondary__toggle");
var secondaryMenu = document.querySelector(".c-menu-secondary");

if (secondaryMenuToggle) {
	secondaryMenuToggle.addEventListener("click", function () {
		if (secondaryMenu.classList.contains("c-menu-secondary--active")) {
			this.setAttribute("aria-expanded", "false");
			secondaryMenu.classList.remove("c-menu-secondary--active");
		} else {
			secondaryMenu.classList.add("c-menu-secondary--active");
			this.setAttribute("aria-expanded", "true");
		}
	});
}
