jQuery(function ($) {
	if ($(".c-filter-list--jobs").length > 0) {
		const jobList = $(".c-filter-list--jobs");
		const jobListContainer = $(jobList).find(".c-filter-list__list");
		const sourceUrl = $(jobList).data("sourceurl");
		const templateListItem = $(jobList).find(".c-filter-list__list-item");

		let categories = [],
			locations = [];
		const perPage = 10;

		$.ajax({
			type: "GET",
			url: sourceUrl,
			dataType: "xml",
			success: function (response) {
				var xml = response;
				var jobs = [];
				$(xml)
					.find("JobPortalPosition")
					.each(function () {
						if (
							$(this).children("PositionLocationTree").children("Name").text()
						) {
							jobs.push(this);
						}
					});

				jobs.sort(function (a, b) {
					var applicationDueA = new Date($(a).children("LastUpdated").text());
					var applicationDueB = new Date($(b).children("LastUpdated").text());

					return applicationDueB - applicationDueA;
				});

				$.each(jobs, function (index, value) {
					var newListItem = $(templateListItem)
						.clone()
						.appendTo(jobListContainer);
					// .show();
					if (index > perPage) {
						$(newListItem).addClass("is-visible");
					}

					$(newListItem)
						.find(".c-filter-list__link")
						.text($(this).children("Name").text());
					$(newListItem)
						.find(".c-filter-list__link")
						.attr("href", $(this).children("AdvertisementUrl").text());

					const location = $(this)
						.children("PositionLocationTree")
						.children("Name")
						.text();
					locations.push(location);
					$(newListItem).find(".c-filter-list__location span").text(location);
					$(newListItem).attr("data-location", location);

					const category = $(this)
						.children("PositionCategory")
						.children("Name")
						.text();
					categories.push(category);
					$(newListItem).find(".c-filter-list__category span").text(category);
					$(newListItem).attr("data-category", category);
				});

				if (jobs.length == 0) $(".c-filter-list__no-results").show();
				[
					{ terms: categories, name: "category" },
					{ terms: locations, name: "location" },
				].forEach(({ terms, name }) => {
					terms
						.filter((value, index, self) => self.indexOf(value) === index)
						.sort()
						.map((item) => {
							const $select = $(`[name="${name}"]`);
							$(`<option value="${item}">${item}</option>`).appendTo($select);
						});
				});

				$(templateListItem).detach();
			},
			error: function () {
				$(".c-filter-list__no-results").show();
			},
		});
	}
});
