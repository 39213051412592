document.addEventListener("DOMContentLoaded", function () {
	if (document.querySelectorAll(".c-banner__carousel").length > 0) {
		var splide = new Splide(".c-banner__carousel", {
			perPage: 1,
			pagination: false,
			rewind: true,
			gap: 104,
			drag: "free",
		});
		splide.mount();
	}
});
