document.addEventListener("DOMContentLoaded", function () {
	if (document.querySelectorAll(".splide").length > 0) {
		var splide = new Splide(".splide", {
			perPage: 3,
			pagination: false,
			rewind: true,
			gap: 104,
			drag: "free",
			breakpoints: {
				767: {
					perPage: 1,
					gap: 24,
				},
				992: {
					perPage: 2,
					gap: 48,
				},
			},
		});
		splide.mount();
	}
});
