jQuery(function ($) {
	if (
		document.querySelectorAll(".c-list__load-more, .c-filter-list__load-more")
			.length > 0
	) {
		document
			.querySelectorAll(".c-list__load-more, .c-filter-list__load-more")
			.forEach((button) => {
				button.addEventListener("click", (e) => {
					let perPage;
					let display;
					if (e.target.classList.contains("c-list__load-more")) {
						perPage = 6;
						display = "block";
					} else if (
						e.target.classList.contains("c-filter-list__load-more--table")
					) {
						perPage = 10;
						display = "table-row";
					} else if (e.target.classList.contains("c-filter-list__load-more")) {
						perPage = 10;
						display = "flex";
					}
					const hiddenItems = Array.from(
						button.parentElement.querySelectorAll(
							".c-list__list-item, .c-filter-list__list-item"
						)
					).filter(
						(listItem) => window.getComputedStyle(listItem).display === "none"
					);

					hiddenItems.slice(0, perPage).forEach((listItem, index) => {
						listItem.style.display = display;

						setTimeout(() => {
							listItem.classList.add("is-visible");
						}, index * 100);
					});

					if (hiddenItems.length <= perPage) {
						button.remove();
					}
				});
			});
	}

	if (document.querySelectorAll(".c-filter-list").length > 0) {
		let facets = {};
		let filters = [];

		if ($(".c-filter-list").hasClass("c-filter-list--files")) {
			Array.from($(".c-filter-list__list-item")).map((item) => {
				const data = $(item).data();
				Object.keys(data).forEach((key, index) => {
					filters[key] = filters[key]
						? { name: key, terms: [data[key], ...filters[key].terms] }
						: { name: key, terms: [data[key]] };
				});
			});

			Object.keys(filters).map((filter) => {
				filters[filter].terms
					.filter((value, index, self) => self.indexOf(value) === index)
					.sort()
					.forEach((option) => {
						const $select = $(`[name="${filters[filter].name}"]`);
						$(`<option value="${option}">${option}</option>`).appendTo($select);
					});
			});
		}

		$(".c-filter-list__filters select").on("change", function () {
			facets[this.name] = this.value;
			filterItems();
		});

		function filterItems() {
			$(".c-filter-list__no-results").hide();
			$(".c-filter-list__load-more").hide();
			$(".c-filter-list__list-item").hide();
			const searchString = Object.keys(facets)
				.filter((key) => {
					return facets[key].length > 0;
				})
				.map((key, index) => `[data-${key}="${facets[key]}"]`)
				.join("");
			if (searchString.length == 0) {
				$(".c-filter-list__load-more").show();
			}
			// if c-filter-list__list-item is a tr, use display table row
			// if c-filter-list__list-item is a div, use display block
			const display = $(".c-filter-list__list-item").is("tr")
				? "table-row"
				: "flex";

			$(`.c-filter-list__list-item${searchString}`).css("display", display);
			if ($(`.c-filter-list__list-item${searchString}`).length == 0) {
				$(".c-filter-list__no-results").show();
			}
		}
	}
});
