// document.addEventListener("DOMContentLoaded", function () {
// 	const videoContainers = document.querySelectorAll(".c-video__video");
// 	if (videoContainers) {
// 		const players = Array.from(videoContainers)
// 			.filter((video) => video.querySelector("iframe").src.includes("vimeo"))
// 			.map((video) => {
// 				const iframe = video.querySelector("iframe");
// 				const player = new Vimeo.Player(iframe);
// 				return player;
// 			});

// 		// players.forEach((player) => {
// 			const playBtn = player.element.parentNode.querySelector(
// 				".c-video__video-play"
// 			);
// 			playBtn.addEventListener("click", function () {
// 				player.getPaused().then(function (paused) {
// 					if (paused) {
// 						players.forEach((player) => {
// 							player.element.parentNode
// 								.querySelector(".c-video__video-play")
// 								.classList.remove("is-playing");
// 							player.pause();
// 						});
// 						playBtn.classList.add("is-playing");
// 						player.play();
// 					} else {
// 						player.pause();
// 						playBtn.classList.remove("is-playing");
// 					}
// 				});
// 			});
// 		});
// 	}
// });

jQuery(function ($) {
	if ($(".c-video__video").exists()) {
		// check if iphone
		// Click on whole video element
		$(".c-video__video").on("click", function (ev) {
			$(this).find("iframe")[0].src += "&autoplay=1";
			$(this).find("iframe").attr("tabindex", "0");
			$(this).addClass("c-video__video--hide");
			ev.preventDefault();
		});
	}
});
