var toggle = document.querySelector(".c-menu__toggle");
var toggleOpenText = document.querySelector(".c-menu__toggle-text-open");
var toggleCloseText = document.querySelector(".c-menu__toggle-text-close");
var menu = document.querySelector(".c-menu");
var body = document.querySelector("body");
var focusableEls = [];
var firstFocusableEl = [];
var lastFocusableEl = [];

var selectors =
	"" +
	"a[href], " +
	"input:not([disabled]), " +
	"select:not([disabled]), " +
	"textarea:not([disabled]), " +
	"button:not([disabled])";

toggle.addEventListener("click", function () {
	if (menu.classList.contains("c-menu--active")) {
		this.setAttribute("aria-expanded", "false");
		menu.classList.remove("c-menu--active");
		body.classList.remove("menu-is-open");
		toggleCloseText.setAttribute("aria-hidden", "true");
		toggleOpenText.setAttribute("aria-hidden", "false");
		focusableEls = [];
		firstFocusableEl = [];
		lastFocusableEl = [];
	} else {
		focusableEls = menu.querySelectorAll(selectors);
		firstFocusableEl = focusableEls[0];
		lastFocusableEl = focusableEls[focusableEls.length - 1];
		toggleCloseText.setAttribute("aria-hidden", "false");
		toggleOpenText.setAttribute("aria-hidden", "true");
		menu.classList.add("c-menu--active");
		this.setAttribute("aria-expanded", "true");
		body.classList.add("menu-is-open");
	}
});

var KEYCODE_TAB = 9;

menu.addEventListener("keydown", function (e) {
	if (e.key === "Tab" || e.keyCode === KEYCODE_TAB) {
		if (e.shiftKey) {
			/* shift + tab */ if (document.activeElement === firstFocusableEl) {
				lastFocusableEl.focus();
				e.preventDefault();
			}
		} /* tab */ else {
			if (document.activeElement === lastFocusableEl) {
				firstFocusableEl.focus();
				e.preventDefault();
			}
		}
	}
});

// function that will close the menu when the user clicks outside of it
window.addEventListener("click", function (e) {
	if (
		!e.target.closest(".c-menu") &&
		menu.classList.contains("c-menu--active")
	) {
		toggle.click();
	}
});

// function that will close the menu when the user presses the escape key
window.addEventListener("keydown", function (e) {
	if (e.key === "Escape" || e.keyCode === 27) {
		if (menu.classList.contains("c-menu--active")) {
			toggle.click();
		}
	}
});

// check if c-menu-primary__item has class c-menu-primary__item--active. If true, add class to parent c-menu-primary called c-menu-primary--submenu-active
var menuPrimaryItems = document.querySelectorAll(".c-menu-primary__item");

menuPrimaryItems.forEach(function (item) {
	if (
		(item.classList.contains("c-menu-primary__item--parent") &&
			item.classList.contains("c-menu-primary__item--active")) ||
		item.classList.contains("c-menu-primary__item--parent--active")
	) {
		item
			.closest(".c-site-header")
			.classList.add("c-site-header--submenu-active");
	}
});

// scroll direction change
var lastScrollTop = 0;
var delta = 100;
var navbarHeight = document.querySelector(".c-site-header").offsetHeight;

window.addEventListener("scroll", function () {
	var st = window.pageYOffset || document.documentElement.scrollTop;
	if (Math.abs(lastScrollTop - st) <= delta) return; // only fire when scrolling more than delta

	if (st > lastScrollTop && st > navbarHeight) {
		// downscroll code
		document
			.querySelector(".c-site-header")
			.classList.add("c-site-header--scrolled");
	} else {
		// upscroll code
		if (st + window.innerHeight < document.body.scrollHeight) {
			document
				.querySelector(".c-site-header")
				.classList.remove("c-site-header--scrolled");
		}
	}
	lastScrollTop = st;
});
