jQuery(document).ready(function ($) {
	if ($(".c-hero--image.c-hero--slider").length) {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: ".c-hero--image.c-hero--slider",
				pin: true, // pin the trigger element while active
				start: "top top", // when the top of the trigger hits the top of the viewport
				end: "+=120%", // end after scrolling 500px beyond the start
				scrub: 0, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
				// markers: true,
				snap: {
					snapTo: 0.9, // snap to the closest label in the timeline
					duration: { min: 0.2, max: 0.2 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
					delay: 0.1, // wait 0.2 seconds from the last scroll event before doing the snapping
					ease: "power1.inOut", // the ease of the snap animation ("power3" by default)
				},
			},
		});

		gsap.set(".c-hero__text-2", {
			pointerEvents: "none",
		});

		tl.to(".c-hero__image--1", {
			opacity: 0,
		})

			.to(".c-hero__image--2", {
				opacity: 1,
				y: "0",
				delay: -0.5,
				duration: 0.5,
			})
			.to(".c-hero__text-1", {
				opacity: 0,
				y: -20,
				delay: -0.5,
				duration: 0.25,
				zIndex: 0,
				pointerEvents: "none",
			})

			.to(".c-hero__text-2", {
				opacity: 1,
				y: -20,
				delay: -0.25,
				duration: 0.25,
				zIndex: 1,
				pointerEvents: "auto",
			});
	}

	ScrollTrigger.batch(".fade-in", {
		onEnter: (elements, triggers) => {
			gsap.to(elements, { opacity: 1, stagger: 0.15, duration: 0.8 });
		},
		start: "top bottom",
	});
});
