if (document.querySelector(".c-horizontal-elements")) {
	const sliders = document.querySelectorAll(".c-horizontal-elements");

	sliders.forEach((slider) => {
		const slider_scroller = slider.querySelector(
			".c-horizontal-elements__list"
		);
		const slider_item_size = slider_scroller.querySelector("div").clientWidth;

		slider
			.querySelector(".c-horizontal-elements__navigation-button--next")
			.addEventListener("click", scrollToNextPage);
		slider
			.querySelector(".c-horizontal-elements__navigation-button--prev")
			.addEventListener("click", scrollToPrevPage);

		function scrollToNextPage() {
			slider_scroller.scrollBy({
				left: slider_item_size,
				behavior: "smooth",
			});
		}
		function scrollToPrevPage() {
			slider_scroller.scrollBy({
				left: -slider_item_size,
				behavior: "smooth",
			});
		}
	});
}
